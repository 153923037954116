import React, { FC, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import BoostedOffersCampaignList from 'components/BoostedOffers/BoostedOffersCampaignList/BoostedOffersCampaignList';
import CreateBoostedOfferCampaignForm from 'components/BoostedOffers/CreateBoostedOfferCampaignForm/CreateBoostedOfferCampaignForm';
import EditBoostedOfferCampaignForm from 'components/BoostedOffers/EditBoostedOfferCampaignForm/EditBoostedOfferCampaignForm';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { BoostedOffer } from 'reduxState/store/boostedOffers/types';

const BoostedOffers: FC = () => {
  const [campaignToEdit, setCampaignToEditState] = useState<BoostedOffer | null>(null);
  const editFormRef: React.RefObject<HTMLDivElement> = useRef(null);
  const { adminAppId } = useParams();
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const navigate = useNavigate();

  if (!adminAppId) {
    navigate('/');
    return <></>;
  }

  const setCampaignToEdit = (campaign: BoostedOffer | null) => {
    setCampaignToEditState(campaign);
    if (campaign)
      setTimeout(() => {
        if (editFormRef.current) editFormRef.current.scrollIntoView();
      }, 0);
  };

  return (
    <div className="boosted-offers">
      <h1 className="text-muted-dark-purple">Boosted Offers</h1>
      <BoostedOffersCampaignList adminAppId={adminAppId} setCampaignToEdit={setCampaignToEdit} />
      {hasWfPermissions &&
        (campaignToEdit ? (
          <EditBoostedOfferCampaignForm
            campaignToEdit={campaignToEdit}
            setCampaignToEdit={setCampaignToEdit}
            editFormRef={editFormRef}
          />
        ) : (
          <CreateBoostedOfferCampaignForm />
        ))}
    </div>
  );
};

export default BoostedOffers;
