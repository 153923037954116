import React, { FC, useCallback, useContext, useEffect, useState, useMemo, MouseEvent, ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Error as ErrorIcon, GetApp, Edit, Search, HighlightOff, ArrowDropDown } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  ClickAwayListener,
  Icon,
  IconButton,
  Link,
  ListItemIcon,
  Tooltip,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { IsMobileContext } from 'App';
import { subYears, subMonths, format } from 'date-fns';
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import {
  getAppPerformanceSummariesByDate,
  getAppPerformanceSummariesGroupedByMerchant,
  getSelectedMerchantPerformanceSummary,
} from 'api/performanceSummary';
import {
  SelectedMerchantPerformanceSummary,
  PerformanceSummaryByDate,
  PerformanceSummaryGroupedByMerchant,
} from 'api/performanceSummary/types';
import Button from 'components/Button/Button';
import { ChooseColumnsDialog } from 'components/Commission/ChooseColumnsDialog/ChooseColumnsDialog';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import ErrorComponent from 'components/Error/Error';
import PerformanceSummaryChart from 'components/PerformanceSummary/PerformanceSummaryChart/PerformanceSummaryChart';
import PerformanceSummaryTable from 'components/PerformanceSummary/PerformanceSummaryTable/PerformanceSummaryTable';
import StatCards from 'components/StatCards/StatCards';
import TextField from 'components/TextField/TextField';
import { exportJSONToCSV, findErrorMessage } from 'helpers';
import { Columns } from 'helpers';
import { searchMerchants } from 'helpers/api';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { DATE_FORMAT } from 'helpers/constants';
import { formatUTCDate } from 'helpers/DateHelpers';
import { isErrorResponse } from 'helpers/typeGuards';
import useScrollTracker from 'hooks/useScrollTracker';
import useTimeOnPageGA from 'hooks/useTimeOnPageGA';
import { useAppDispatch, useAppSelector } from 'reduxState/hooks';
import { useGetApplicationDetailsQuery } from 'reduxState/store/application/api';
import {
  statSummaryColumnsSelector,
  performanceSummaryFilterValuesSelector,
  performanceSummaryDidUserChangeDatesSelector,
} from 'reduxState/store/performanceSummary/selectors';
import {
  setFilterValues,
  setPerformanceSummaryDidUserChangeDates,
  setPerformanceSummaryColumns,
} from 'reduxState/store/performanceSummary/slice';
import { PerformanceSummaryColumn, MerchantSearchResult } from 'reduxState/store/performanceSummary/types';
import { getPrevInterval } from 'reduxState/store/performanceSummary/utils';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import infoIcon from 'static/images/info-icon.png';
import { isMerchantSearchResultsList } from './helpers';
import './PerformanceSummary.scss';

const ACTIONS_MENU_ID = 'actions-menu';
const VIEW_BY_MENU_ID = 'view-by-menu';

const getDefaultStartDate = (groupBy: string): string => {
  switch (groupBy) {
    case 'month': {
      return subYears(new Date(), 1).toString();
    }
    default: {
      // handles groupBy day/merchants
      return subMonths(new Date(), 1).toString();
    }
  }
};

interface PerformanceSummaryPageProps {
  applicationId: string;
  appName: string;
  adminAppId: string;
}

const PerformanceSummaryPage: FC<React.PropsWithChildren<PerformanceSummaryPageProps>> = ({
  applicationId,
  appName,
  adminAppId,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedMerchant, setSelectedMerchant] = useState<MerchantSearchResult | null>(null);
  const [searchResults, setSearchResults] = useState<MerchantSearchResult[]>([]);
  const [noSearchResults, setNoSearchResults] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);

  const [editableColumns, setEditableColumns] = useState<PerformanceSummaryColumn[]>([]);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLButtonElement | null>(null);

  const filterValues = useAppSelector(performanceSummaryFilterValuesSelector);
  const didUserChangeDates = useAppSelector(performanceSummaryDidUserChangeDatesSelector);
  const { startDate, endDate, groupBy, sortOrder, sortBy } = filterValues;

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [stats, setStats] = useState<
    PerformanceSummaryByDate[] | PerformanceSummaryGroupedByMerchant[] | SelectedMerchantPerformanceSummary[]
  >([]);
  const [prevIntervalStats, setPrevIntervalStats] = useState<
    PerformanceSummaryByDate[] | PerformanceSummaryGroupedByMerchant[] | SelectedMerchantPerformanceSummary[]
  >([]);

  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);

  useTimeOnPageGA(user, applicationId, 'Performance Summary');
  useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
    !hasWfPermissions &&
      ReactGA.event('scroll_graph', {
        appId: applicationId,
        pageName: 'Performance Summary',
        scrollDepth: scrollY,
      });
  });

  const dispatch = useAppDispatch();

  const columnType = useMemo((): 'selectedMerchant' | 'groupByMerchant' | 'apiDateColumns' | 'nonAPIDateColumns' => {
    if (selectedMerchant) return 'selectedMerchant';
    if (groupBy === 'merchants') return 'groupByMerchant';

    const isAPIClient = appName.includes('API');
    return isAPIClient ? 'apiDateColumns' : 'nonAPIDateColumns';
  }, [selectedMerchant, groupBy, appName]);

  const columns: PerformanceSummaryColumn[] = useAppSelector(state => statSummaryColumnsSelector(state, columnType));

  const { data: deviceData, error: deviceTokenError, isFetching: isDeviceTokenFetching } = useGetDeviceQuery(
    applicationId,
  );
  const deviceToken = deviceData?.DeviceToken as string;

  if (!isDeviceTokenFetching && deviceTokenError) {
    console.error(deviceTokenError);
  }

  const { data: appPolicy, error: appPolicyError } = useGetApplicationDetailsQuery(
    {
      appId: applicationId,
      deviceToken,
    },
    {
      skip: !deviceToken || typeof deviceToken !== 'string',
    },
  );

  if (appPolicyError) {
    toast.error('Failed to retrieve application details.');
    console.error(appPolicyError);
  }

  const applicationCurrency = appPolicy?.Currency || 'USD';

  const visibleColumns = useMemo((): PerformanceSummaryColumn[] => {
    return editableColumns.filter((column: PerformanceSummaryColumn) => column.visible);
  }, [editableColumns]);

  const isMobile = useContext(IsMobileContext);

  const onStartDateChange = useCallback(
    (newStartDate: Date) => {
      dispatch(
        setFilterValues({
          startDate: newStartDate.toString(),
        }),
      );

      if (!didUserChangeDates) dispatch(setPerformanceSummaryDidUserChangeDates(true));

      !hasWfPermissions &&
        ReactGA.event('click_date', {
          appId: applicationId,
          userId: user!.sub,
          startDate: newStartDate,
          endDate,
          pageName: 'Performance Summary',
        });
    },
    [dispatch, applicationId, endDate, user, didUserChangeDates, hasWfPermissions],
  );

  const onEndDateChange = useCallback(
    (newEndDate: Date) => {
      dispatch(
        setFilterValues({
          endDate: newEndDate.toString(),
        }),
      );

      if (!didUserChangeDates) dispatch(setPerformanceSummaryDidUserChangeDates(true));

      !hasWfPermissions &&
        ReactGA.event('click_date', {
          appId: applicationId,
          userId: user!.sub,
          startDate,
          endDate: newEndDate,
          pageName: 'Performance Summary',
        });
    },
    [dispatch, applicationId, startDate, user, didUserChangeDates, hasWfPermissions],
  );

  const openTooltip = (): void => {
    setTooltipOpened(true);
  };

  const closeTooltip = (): void => {
    setTooltipOpened(false);
  };

  const openActionsMenu = (event: MouseEvent<HTMLButtonElement>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const closeActionsMenu = (): void => {
    setActionsAnchorEl(null);
  };

  // TODO: fix types
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchPerformanceSummaryHandler = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setSearchValue(event.target.value);
      if (event.target.value === '') {
        setSelectedMerchant(null);
        setSearchResults([]);
      }
    }, 500),
    [],
  );

  const sortPerformanceSummary = useCallback(
    (queryName: string): void => {
      if (sortBy === queryName) {
        dispatch(
          setFilterValues({
            sortOrder: sortOrder === 'desc' ? 'asc' : 'desc',
          }),
        );
      } else {
        dispatch(
          setFilterValues({
            sortBy: queryName,
            sortOrder: 'desc',
          }),
        );
      }
    },
    [dispatch, sortBy, sortOrder],
  );

  const exportToCsv = async (): Promise<void> => {
    setDownloading(true);

    let csv;
    const dateFormat = groupBy === 'month' ? 'MMM-yyyy' : 'dd-MMM-yyyy';
    if (selectedMerchant) {
      const selectedMerchantStatSummaries = (stats as SelectedMerchantPerformanceSummary[]).map(
        (stat: SelectedMerchantPerformanceSummary) => {
          return {
            ...stat,
            ReportDate: formatUTCDate(new Date(stat.ReportDate), dateFormat),
          };
        },
      );

      csv = exportJSONToCSV(
        selectedMerchantStatSummaries,
        visibleColumns as Columns<SelectedMerchantPerformanceSummary>[],
      );
    } else if (groupBy === 'merchants') {
      csv = exportJSONToCSV(
        stats as PerformanceSummaryGroupedByMerchant[],
        visibleColumns as Columns<PerformanceSummaryGroupedByMerchant>[],
      );
    } else {
      const statSummariesByDate = (stats as PerformanceSummaryByDate[]).map((stat: PerformanceSummaryByDate) => {
        return {
          ...stat,
          ReportDate: formatUTCDate(new Date(stat.ReportDate), dateFormat),
        };
      });
      csv = exportJSONToCSV(statSummariesByDate, visibleColumns as Columns<PerformanceSummaryByDate>[]);
    }

    if (csv) {
      const blob = new Blob([csv], { type: 'text/csv' });

      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = URL.createObjectURL(blob);

      const formattedStartDate = format(new Date(startDate), DATE_FORMAT);
      const formattedEndDate = format(new Date(endDate), DATE_FORMAT);

      a.setAttribute(
        'download',
        `${appName.split(' ').join('_')}_Performance_${formattedStartDate}_to_${formattedEndDate}.csv`,
      );

      // trigger download by simulating click
      a.click();

      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

      closeActionsMenu();
      !hasWfPermissions &&
        ReactGA.event('click_export', {
          action: 'click',
          appId: applicationId,
          userId: user!.sub,
          pageName: 'Performance Summary',
          totalRecords: stats.length,
        });
    } else {
      setDownloadError(true);
      setTimeout((): void => setDownloadError(false), 3000);
    }

    setDownloading(false);
  };

  const openDialog = (): void => {
    setDialogOpened(true);
    closeActionsMenu();
  };

  const closeDialog = (nextColumns?: PerformanceSummaryColumn[]): void => {
    setDialogOpened(false);

    if (!nextColumns) return;
    dispatch(
      setPerformanceSummaryColumns({
        columns: nextColumns,
        groupBy: columnType,
      }),
    );
    setEditableColumns(nextColumns);

    // Track which columns visibility changes and record the changed columns.
    if (!hasWfPermissions) {
      // Encased the logic below since this will only be used to Record the event.
      const currentColumnsHashMap: Record<string, PerformanceSummaryColumn> = columns.reduce((acc, column) => {
        if (!acc[column.name]) {
          acc[column.name] = column;
        }
        return acc;
      }, {} as Record<string, PerformanceSummaryColumn>);

      const editedColumns = nextColumns.reduce((edited: string[], nextColumn: PerformanceSummaryColumn) => {
        if (currentColumnsHashMap[nextColumn.name].visible !== nextColumn.visible) {
          edited.push(nextColumn.name);
        }
        return edited;
      }, []);

      ReactGA.event('click_edit', {
        appId: applicationId,
        userId: user!.sub,
        editedColumns: editedColumns,
        pageName: 'Performance Summary',
      });
    }
  };
  const getViewName = (): string => {
    switch (groupBy) {
      case 'day':
        return 'Day';
      case 'month':
        return 'Month';
      case 'merchants':
      default:
        return 'Merchant';
    }
  };

  const selectMerchant = async (value: MerchantSearchResult | null): Promise<void> => {
    if (!value) return;
    const { Value: merchantName } = value;
    const newGroupBy = groupBy === 'merchants' ? 'day' : groupBy;
    dispatch(
      setFilterValues({
        groupBy: newGroupBy,
      }),
    );
    setSelectedMerchant(value);
    setInputValue(merchantName);
    !hasWfPermissions &&
      ReactGA.event('search_merchant', {
        appId: applicationId,
        userId: user!.sub,
        pageName: 'Performance Summary',
        merchantName,
      });
  };

  const clearSearch = (): void => {
    setInputValue('');
    setSearchValue('');
    setSelectedMerchant(null);
    setSearchResults([]);
  };

  const changeGroupByView = async (newGroupBy: 'merchants' | 'day' | 'month') => {
    let newStartDate;
    let sortBy = '';
    let sortOrder: 'asc' | 'desc' = 'asc';

    if (!didUserChangeDates) {
      switch (newGroupBy) {
        case 'merchants':
          newStartDate = subMonths(new Date(), 1).toString();
          sortBy = 'net_commission_amount';
          sortOrder = 'desc';
          break;
        case 'month':
          newStartDate = subYears(new Date(), 1).toString();
          break;
        case 'day':
          newStartDate = subMonths(new Date(), 1).toString();
      }
    }

    dispatch(
      setFilterValues({
        startDate: newStartDate ? newStartDate : startDate,
        sortBy,
        sortOrder,
        groupBy: newGroupBy,
      }),
    );

    closeActionsMenu();
    !hasWfPermissions &&
      ReactGA.event(`select_${newGroupBy}`, {
        appId: applicationId,
        userId: user!.sub,
      });
  };

  const handleDateChange = (dateRange: DateRange | null) => {
    if (!dateRange) return;
    const [start, end] = dateRange;

    onStartDateChange(start);
    onEndDateChange(end);
  };

  const handleOnDateClear = async () => {
    dispatch(
      setFilterValues({
        startDate: getDefaultStartDate(groupBy),
        endDate: new Date().toString(),
      }),
    );
    dispatch(setPerformanceSummaryDidUserChangeDates(false));
  };

  const getNoOptionsText = useCallback(() => {
    if (downloadError) return 'Error in search';
    if (noSearchResults) return 'No merchants';
    return 'Type to search';
  }, [downloadError, noSearchResults]);

  // Table/StatCard fetching logic
  useEffect(() => {
    const { startDate, endDate, sortOrder, sortBy, groupBy } = filterValues;

    const { start: prevStart, end: prevEnd } = getPrevInterval({
      start: new Date(filterValues.startDate),
      end: new Date(filterValues.endDate),
    });

    const baseParams = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      sortOrder,
      sortBy,
    };

    const prevBaseParams = {
      startDate: prevStart,
      endDate: prevEnd,
      sortOrder,
      sortBy,
    };

    const fetchData = async () => {
      if (!deviceToken) return;

      let currentDataPromise;
      let prevDataPromise;

      if (selectedMerchant && groupBy !== 'merchants') {
        setIsDataLoading(true);
        currentDataPromise = getSelectedMerchantPerformanceSummary(applicationId, deviceToken, {
          ...baseParams,
          groupBy,
          merchantId: selectedMerchant.ID,
        });
        prevDataPromise = getSelectedMerchantPerformanceSummary(applicationId, deviceToken, {
          ...prevBaseParams,
          groupBy,
          merchantId: selectedMerchant.ID,
        });
      } else if (groupBy === 'merchants') {
        setIsDataLoading(true);
        currentDataPromise = getAppPerformanceSummariesGroupedByMerchant(applicationId, deviceToken, baseParams);
        prevDataPromise = getAppPerformanceSummariesGroupedByMerchant(applicationId, deviceToken, prevBaseParams);
      } else {
        setIsDataLoading(true);
        currentDataPromise = getAppPerformanceSummariesByDate(applicationId, deviceToken, { ...baseParams, groupBy });
        prevDataPromise = getAppPerformanceSummariesByDate(applicationId, deviceToken, { ...prevBaseParams, groupBy });
      }

      let [currentData, prevData] = await Promise.all([currentDataPromise, prevDataPromise]);

      if (isErrorResponse(currentData)) {
        console.warn(findErrorMessage(currentData));
        setIsDataLoading(false);
        setFetchError(true);
        currentData = [];
      }

      if (isErrorResponse(prevData)) {
        console.warn(findErrorMessage(prevData));
        setIsDataLoading(false);
        setFetchError(true);
        prevData = [];
      }

      setStats(currentData);
      setPrevIntervalStats(prevData);
      setIsDataLoading(false);
    };

    fetchData();
  }, [
    applicationId,
    deviceToken,
    deviceTokenError,
    isDeviceTokenFetching,
    setIsDataLoading,
    selectedMerchant,
    filterValues,
  ]);

  useEffect(() => {
    if (searchValue.length <= 2) return;
    setSearchLoading(true);
    setNoSearchResults(false);
    (async () => {
      try {
        const searchMerchantsResponse: MerchantSearchResult[] | any = await searchMerchants(searchValue, applicationId);
        if (!isMerchantSearchResultsList(searchMerchantsResponse)) {
          const message = findErrorMessage(searchMerchantsResponse);
          throw new Error(message);
        }
        setSearchResults(searchMerchantsResponse);
        if (!searchMerchantsResponse.length) {
          setNoSearchResults(true);
        }
      } catch (error) {
        setDownloadError(true);
        console.error(error);
      }
      setSearchLoading(false);
    })();
  }, [searchValue, applicationId]);

  useEffect(() => {
    setEditableColumns(columns);
  }, [groupBy, selectedMerchant, columns]);

  return fetchError ? (
    <ErrorComponent />
  ) : (
    <div className="stat-summary">
      <div className="stat-summary-title-block">
        <h1 className="text-muted-dark-purple">Performance Summary</h1>
      </div>
      <div className="stat-summary-header">
        <DateRangePicker
          label="Event Date"
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          onDateChange={handleDateChange}
          onDateClear={handleOnDateClear}
        />

        <div className="actions">
          <Autocomplete
            className="merchant-search"
            options={searchResults}
            getOptionLabel={option => option.Value}
            filterOptions={x => x} // Disable component filtering since backend will handle search
            onChange={(e, value) => selectMerchant(value)}
            blurOnSelect={true}
            inputValue={inputValue}
            value={selectedMerchant}
            loading={searchLoading}
            noOptionsText={getNoOptionsText()}
            renderInput={params => (
              <TextField
                {...params}
                className="table-filter-field"
                variant="outlined"
                placeholder="Search merchants"
                onChange={(event): void => {
                  event.persist();
                  setInputValue(event.target.value);
                  onSearchPerformanceSummaryHandler(event);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchLoading && <CircularProgress size={20} />}
                      {selectedMerchant || searchResults.length > 0 ? (
                        <InputAdornment position="end">
                          <HighlightOff className="highlight-off-icon" onClick={clearSearch} />
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
          <div className="btn-dropdown">
            <Button
              className="button-table-action"
              disabled={false}
              variant="outlined"
              id={VIEW_BY_MENU_ID}
              aria-controls={VIEW_BY_MENU_ID}
              aria-haspopup="true"
              endIcon={<ArrowDropDown />}
              onClick={openActionsMenu}
            >
              {`View by ${getViewName()}`}
            </Button>

            <Button
              className="button-table-action"
              disabled={false}
              variant="outlined"
              id={ACTIONS_MENU_ID}
              aria-controls={ACTIONS_MENU_ID}
              aria-haspopup="true"
              endIcon={<ArrowDropDown />}
              onClick={openActionsMenu}
            >
              Actions
            </Button>
            {isMobile ? (
              <ClickAwayListener onClickAway={closeTooltip}>
                <Tooltip
                  title="Summary is based on Event Date, updated every 2 hours, and aggregated in PST"
                  onClose={closeTooltip}
                  open={tooltipOpened}
                  leaveTouchDelay={3000}
                >
                  <IconButton onClick={openTooltip} size="large">
                    <Icon>
                      <img className="help-icon" src={infoIcon} height={24} width={24} alt="More information icon" />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            ) : (
              <Link href="https://kb.wildfire-corp.com/article/hm1nrlmnmdixutptyos" target="_blank">
                <Icon>
                  <img className="help-icon" src={infoIcon} height={24} width={24} alt="More information icon" />
                </Icon>
              </Link>
            )}
          </div>
        </div>

        <Menu
          id={ACTIONS_MENU_ID}
          anchorEl={actionsAnchorEl}
          keepMounted
          open={!!(actionsAnchorEl && actionsAnchorEl.id === ACTIONS_MENU_ID)}
          onClose={closeActionsMenu}
        >
          <MenuItem onClick={openDialog}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Edit Columns</ListItemText>
          </MenuItem>

          <ChooseColumnsDialog open={dialogOpened} columns={editableColumns} onClose={closeDialog} />

          <MenuItem
            className="progress-wrapper"
            disabled={stats.length === 0 || downloadError || downloading}
            onClick={exportToCsv}
          >
            <ListItemIcon>{downloadError ? <ErrorIcon /> : <GetApp />}</ListItemIcon>
            <ListItemText>Export</ListItemText>
            {downloading && <CircularProgress className="progress" size={24} />}
          </MenuItem>
        </Menu>

        <Menu
          id={VIEW_BY_MENU_ID}
          anchorEl={actionsAnchorEl}
          keepMounted
          open={!!(actionsAnchorEl && actionsAnchorEl.id === VIEW_BY_MENU_ID)}
          onClose={closeActionsMenu}
        >
          <MenuItem
            onClick={() => {
              changeGroupByView('day');
            }}
          >
            <ListItemText>{`View by Day`}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeGroupByView('month');
            }}
          >
            <ListItemText>{`View by Month`}</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={!!selectedMerchant}
            onClick={() => {
              changeGroupByView('merchants');
            }}
          >
            <ListItemText>{`View by Merchant`}</ListItemText>
          </MenuItem>
        </Menu>
      </div>
      <StatCards
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        stats={stats}
        currency={applicationCurrency}
        prevIntervalStats={prevIntervalStats}
      />
      <PerformanceSummaryChart
        applicationCurrency={applicationCurrency}
        appId={applicationId}
        startDate={startDate}
        endDate={endDate}
        groupBy={groupBy}
        sortBy={sortBy}
        selectedMerchantId={selectedMerchant?.ID || null}
      />
      <PerformanceSummaryTable
        performanceSummary={stats}
        columns={visibleColumns}
        sortBy={sortBy}
        sortOrder={sortOrder}
        groupBy={groupBy}
        sortPerformanceSummary={sortPerformanceSummary}
        adminAppId={adminAppId}
        applicationCurrency={applicationCurrency}
        isDataLoading={isDataLoading}
        error={fetchError}
      />
    </div>
  );
};

export default PerformanceSummaryPage;
