export interface Commission {
  Amounts: Amount[];
  ApplicationID: number;
  CommissionID: number;
  CouponCodes: string;
  CreatedDate: string;
  DeviceID: number;
  DeviceXID: string;
  EventDate: string;
  MerchantID: number;
  MerchantOrderID: string;
  MerchantSKU: string;
  ModifiedDate: string;
  SaleAmount: Omit<Amount, 'SplitPart'>;
  ShoppingTripCode: string;
  Status: string;
  TrackingCode?: string;
}

export interface DisplayCommission extends Omit<Commission, 'Amounts' | 'SaleAmount'> {
  UserCommission: {
    currency: string;
    amount: string;
  };
  PartnerCommission: {
    currency: string;
    amount: string;
  };
  BaseCommission: {
    currency: string;
    amount: string;
  };
  BoostedCommission: {
    currency: string;
    amount: string;
  };
  SaleAmount: {
    currency: string;
    amount: string;
  };
}

export interface CommissionHistoryDownloadData {
  ApplicationID: number;
  CreatedDate: string;
  ID: number;
  ModifiedDate: string;
  QueryString: string;
  Status: string;
  Url?: string;
  User?: string;
}

export interface CsvCommission extends Omit<Commission, 'Amounts' | 'SaleAmount'> {
  BaseCommission: string;
  BaseCommissionCurrency: string;
  BoostedCommission: string;
  BoostedCommissionCurrency: string;
  PartnerCommission: string;
  PartnerCommissionCurrency: string;
  SaleAmount: string;
  SaleAmountCurrency: string;
  UserCommission?: string;
  UserCommissionCurrency?: string;
}

interface Amount {
  Amount: string;
  Currency: string;
  BaseCommissionAmount: string;
  BoostedCommissionAmount: string;
  SplitPart: SplitPart;
}

type SplitPart = 'DEVICE' | 'APPLICATION' | 'WILDFIRE' | 'RESERVE';

export interface AppGroupCommissionWithMerchantName extends Commission {
  MerchantName: string;
  ApplicationName: string;
}

export interface CommissionWithMerchantName extends DisplayCommission {
  MerchantName: string;
}

export interface CsvCommissionWithMerchantName extends CsvCommission {
  MerchantName: string;
}

export type AppGroupCsvCommissionWithMerchantName = Omit<
  CsvCommissionWithMerchantName,
  | 'PartnerCommission'
  | 'PartnerCommissionCurrency'
  | 'UserCommission'
  | 'UserCommissionCurrency'
  | 'BoostedCommission'
  | 'BoostedCommissionCurrency'
  | 'BaseCommission'
  | 'BaseCommissionCurrency'
> & {
  ApplicationName: string;
};

export interface ListCommission {
  Commissions: Commission[];
  PrevCursor: string | null;
  NextCursor: string | null;
}

export interface CommissionState {
  columns: CommissionColumn[];
}

export interface AppGroupCommissionState {
  columns: AppGroupCommissionColumn[];
}

export interface AppGroupCommissionColumn {
  accessor: Exclude<keyof AppGroupCommissionWithMerchantName, 'Amounts'>;
  name: string;
  visible: boolean;
  nonEditable?: boolean;
}

export interface CommissionColumn {
  accessor: Exclude<keyof CommissionWithMerchantName, 'ApplicationID'>;
  name: string;
  visible: boolean;
  nonEditable?: boolean;
}

export type CombinedColumn = CommissionColumn | AppGroupCommissionColumn;

export interface CombinedColumnVisible {
  visible: true;
}

export interface CsvCommissionColumn {
  accessor:
    | Exclude<keyof CommissionWithMerchantName, 'ApplicationID'>
    | 'BaseCommissionCurrency'
    | 'BoostedCommissionCurrency'
    | 'PartnerCommissionCurrency'
    | 'UserCommissionCurrency'
    | 'SaleAmountCurrency';
  name: string;
}

export interface AppGroupCsvCommissionColumn {
  accessor:
    | Exclude<keyof AppGroupCommissionWithMerchantName, 'ApplicationID' | 'MerchantSKU' | 'Amounts'>
    | 'SaleAmountCurrency';
  name: string;
}

export const userCommissionColumn: CommissionColumn = {
  accessor: 'UserCommission',
  name: 'User Commission',
  visible: true,
};

export interface FilterStatus {
  PENDING: boolean;
  READY: boolean;
  PAID: boolean;
  CONFIRMED: boolean;
}

export interface SetCommissionColumnsPayload {
  columns: CommissionColumn[];
}

export interface SetAppGroupCommissionColumnsPayload {
  columns: AppGroupCommissionColumn[];
}

export interface AppGroupCommissionColumnVisible extends AppGroupCommissionColumn {
  visible: true;
}

export interface CommissionColumnVisible extends CommissionColumn {
  visible: true;
}

// api request types
export interface GetCommissionsQueryArgs {
  appId: string;
  filters: {
    startDate: string;
    endDate: string;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
    searchQueries: string;
    pageNumber: number | null;
    pageSize: number;
    advancedSearch?: boolean;
  };
}

export interface GetAppGroupCommissionsQueryArgs {
  adminId: string;
  filters: {
    startDate: string;
    endDate: string;
    searchQueries: string;
    pageNumber: number | null;
    pageSize: number;
    ids: string;
  };
}

export interface GetSendCommissionCallbackQueryArgs {
  appId: string;
  commissionId: number;
}

export interface GetCommissionDownloadJobArgs {
  appId: string;
  deviceToken: string;
  downloadId: string;
}

export interface GetAllCommissionHistoryDownloadsArgs {
  appId: string;
  userId: string;
  deviceToken: string;
}

export interface CreateCommissionDownloadArgs {
  applicationId: string;
  deviceToken: string;
  userToken: string;
  queryString: string;
}
