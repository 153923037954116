import React, { useState, useEffect } from 'react';
import { Clear } from '@mui/icons-material';
import { Checkbox, IconButton, InputAdornment, LinearProgress, TextField } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import Concept from './Concept';
import { selectedConceptsType } from './ConceptManager';
import { filterConcepts } from './util';
import { Concept as ConceptType } from '../../reduxState/store/concept/types';

export interface ConceptListProps {
  title: string;
  concepts: ConceptType[];
  selectedRef: React.MutableRefObject<selectedConceptsType>;
  isLoading: boolean;
  conceptEditSavedCallback: React.MutableRefObject<() => void>;
}

const ConceptList = ({
  title,
  concepts,
  selectedRef,
  isLoading,
  conceptEditSavedCallback,
}: ConceptListProps): JSX.Element => {
  const [filter, setFilter] = useState('');
  const [selectedConcepts, setSelectedConcepts] = useState<selectedConceptsType>({});

  const selectedCount = Object.keys(selectedConcepts).length;
  const allSelected = selectedCount !== 0 && selectedCount === concepts.length;
  const filteredConcepts = filterConcepts(concepts, filter);
  const toggleSelectConcept = (concept: ConceptType) => {
    const newState = { ...selectedConcepts };

    if (newState[concept.ID] === undefined) {
      newState[concept.ID] = concept;
    } else {
      delete newState[concept.ID];
    }

    setSelectedConcepts(newState);
  };

  const toggleSelectAllConcepts = () => {
    if (allSelected) {
      setSelectedConcepts({});
      return;
    }
    const all = filteredConcepts.reduce((acc: selectedConceptsType, con: ConceptType) => {
      acc[con.ID] = con;
      return acc;
    }, {});

    setSelectedConcepts(all);
  };

  useEffect(() => {
    selectedRef.current = selectedConcepts;
    conceptEditSavedCallback.current = () => setSelectedConcepts({});
  }, [selectedConcepts, conceptEditSavedCallback, selectedRef]);

  return (
    <div className="flex flex-1 flex-col">
      <h3>{`${title} (${selectedCount}/${concepts.length})`}</h3>
      <TextField
        label="Filter"
        variant="standard"
        value={filter}
        onChange={(e): void => setFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Checkbox
                color="default"
                checked={allSelected}
                indeterminate={selectedCount > 0 && !allSelected}
                onChange={toggleSelectAllConcepts}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="clear filter" size="small" edge="end" onClick={() => setFilter('')}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isLoading && <LinearProgress />}
      {/* per autosizer docs; otherwise grows larger than the page */}
      <div className="flex-auto">
        <AutoSizer>
          {/* @ts-ignore */}
          {({ height, width }): JSX.Element => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={32}
              itemCount={filteredConcepts.length}
              itemData={filteredConcepts}
            >
              {(props): JSX.Element => (
                <Concept
                  {...props}
                  selectedConcepts={selectedConcepts}
                  onSelect={(concept: ConceptType) => toggleSelectConcept(concept)}
                />
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default ConceptList;
