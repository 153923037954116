import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Error as ErrorIcon, Search, HighlightOff, Clear } from '@mui/icons-material';
import { Card, CircularProgress, Link, Menu, Icon, InputAdornment, TextField, Dialog } from '@mui/material';
import { format, addDays } from 'date-fns';
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { DateRange } from 'rsuite/esm/DateRangePicker';

import Button from 'components/Button/Button';
import { ChooseColumnsMenu } from 'components/CommissionHistoryTableV2/ChooseColumnsMenu/ChooseColumnsMenu';
import { CommissionHistoryTableV2 } from 'components/CommissionHistoryTableV2/CommissionHistoryTable/CommissionHistoryTableV2';
import { exportJSONToCSV, findErrorMessage } from 'helpers';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { DATE_FORMAT } from 'helpers/constants';
import { useAppSelector, useAppDispatch } from 'reduxState/hooks';
import {
  useLazyGetAppGroupCommissionsQuery,
  useCreateCommissionDownloadMutation,
} from 'reduxState/store/commission/api';
import { appGroupCommissionColumnsSelector } from 'reduxState/store/commission/selectors';
import { setAppGroupCommissionColumns } from 'reduxState/store/commission/slice';
import {
  AppGroupCommissionColumn,
  AppGroupCommissionWithMerchantName,
  AppGroupCsvCommissionColumn,
  AppGroupCsvCommissionWithMerchantName,
  CombinedColumn,
  Commission,
} from 'reduxState/store/commission/types';
import { useLazyGetMerchantsQuery } from 'reduxState/store/merchant/api';
import { Merchant } from 'reduxState/store/merchant/types';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { AppGroup } from 'reduxState/store/user/types';

import exportIcon from 'static/images/export-icon-black.png';
import infoIcon from 'static/images/info-icon.png';
import menuBookIcon from 'static/images/menu-book-black.png';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';

interface CommissionHistoryProps {
  appGroup: AppGroup;
}

const AppGroupCommissionHistory: FC<React.PropsWithChildren<CommissionHistoryProps>> = ({ appGroup }) => {
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));
  const appIds: string[] = [];
  appGroup.applications.forEach(app => appIds.push(app.id));

  const [startDate, setStartDate] = useState<Date | null>(oneWeekAgo);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [editColumnsAnchorEl, setEditColumnsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [callbackVisible, setCallbackVisible] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadedCommissions, setLoadedCommissions] = useState<AppGroupCommissionWithMerchantName[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [pageNumber, setPageNumber] = useState(2);
  const [merchants, setMerchants] = useState<{ [MerchantID: number]: Merchant }>({});
  const [modalOpen, setModalOpen] = useState(false);

  const adminId: string = appGroup.adminId.toString();
  const adminName: string = appGroup.adminName;
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);

  const columns = useAppSelector(appGroupCommissionColumnsSelector);

  const [getCommissions, { error: commissionError }] = useLazyGetAppGroupCommissionsQuery();
  const [createCommissionDownloadJob, { error: commissionDownloadError }] = useCreateCommissionDownloadMutation();
  const [getMerchants] = useLazyGetMerchantsQuery();

  const { data: deviceData, error: deviceTokenError, isFetching: isDeviceTokenFetching } = useGetDeviceQuery(adminId);
  const deviceToken = deviceData?.DeviceToken as string;

  const handleGetMerchants = async (merchantIds: number[]) => {
    try {
      const { data, error } = await getMerchants({ appId: adminId, deviceToken, merchantIds });

      if (error) {
        console.error(findErrorMessage(error));
        toast.error('Failed to get merchants.');
        return;
      }

      const newMerchantsHashMap =
        data?.merchants.reduce((acc, merchant) => {
          if (!acc[merchant.ID]) acc[merchant.ID] = merchant;
          return acc;
        }, merchants) || merchants;

      setMerchants(newMerchantsHashMap);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetCommissions = async (pageNum?: number, pageSize?: number) => {
    let commissions;

    if (!startDate || !endDate) {
      toast.error('Please select start/end date.');
      return [];
    }

    try {
      const { Commissions } = await getCommissions({
        adminId,
        filters: {
          startDate: format(new Date(startDate), DATE_FORMAT),
          endDate: format(addDays(new Date(endDate), 1), DATE_FORMAT),
          searchQueries: searchTerm,
          pageNumber: pageNum || null,
          pageSize: pageSize || 100,
          ids: appIds.join(','),
        },
      }).unwrap();
      commissions = Commissions;
    } catch (error) {
      console.error('Failed to retrieve commission data', error);
      toast.error('Failed to retrieve commission data.');
    }

    if (commissions && Array.isArray(commissions)) {
      const uniqueMerchantIDs = commissions
        .map((commission: Commission) => commission.MerchantID)
        .filter((merchantId: number) => !merchants[merchantId]);

      if (uniqueMerchantIDs.length !== 0) {
        await handleGetMerchants(uniqueMerchantIDs);
      }

      const commissionsWithMerchantNames = generateCommissionsWithMerchantNames(commissions);
      setPageNumber(pageNumber + 1);
      return commissionsWithMerchantNames;
    } else {
      return [];
    }
  };

  const generateCommissions = async () => {
    setIsLoading(true);
    setHasNextPage(true);
    setLoadedCommissions([]);
    const commissionsWithMerchantNames = await handleGetCommissions(1);
    if (commissionsWithMerchantNames) {
      setLoadedCommissions(commissionsWithMerchantNames);
      setPageNumber(2);
      setIsLoading(false);
    }
  };

  const generateCommissionsWithMerchantNames = (commissions: Commission[]): AppGroupCommissionWithMerchantName[] => {
    const commissionsWithMerchantNames = commissions.map((commission: Commission) => {
      const MerchantName = merchants[commission.MerchantID]?.Name || 'N/A';
      const ApplicationName = appGroup.applications.filter(app => app.id === commission.ApplicationID.toString())[0]
        .name;

      return {
        ...commission,
        MerchantName,
        ApplicationName,
      };
    });

    return commissionsWithMerchantNames;
  };

  const handleLoadMoreCommissions = async (): Promise<void> => {
    let newCommissions: AppGroupCommissionWithMerchantName[];
    try {
      newCommissions = await handleGetCommissions(pageNumber);
      if (newCommissions.length !== 0) {
        setLoadedCommissions(loadedCommissions.concat(newCommissions));
      } else {
        setHasNextPage(false);
      }
    } catch (error) {
      console.error('Failed to load more commissions.', error);
    }
  };

  const handleDateChange = (dateRange: DateRange | null) => {
    if (!dateRange) return;
    const [start, end] = dateRange;

    setStartDate(start);
    setEndDate(end);
  };

  const exportToCsv = async (): Promise<void> => {
    if (!startDate || !endDate || !deviceToken) return;
    setIsDownloading(true);
    const csvCommissions = await handleGetCommissions(1, 2000);
    const formattedStartDate = format(startDate, DATE_FORMAT);
    const formattedEndDate = format(endDate, DATE_FORMAT);

    if (csvCommissions.length >= 2000) {
      try {
        const queryString =
          `EventDate:>"${formattedStartDate}" ` + `EventDate:<"${formattedEndDate}" ` + encodeURIComponent(searchTerm);
        await createCommissionDownloadJob({
          applicationId: adminId,
          deviceToken,
          userToken: user!.sub!,
          queryString,
        }).unwrap();
      } catch (error) {
        console.error(error);
      } finally {
        setModalOpen(true);
        setIsDownloading(false);
      }
      return;
    }

    /**
     *  Comissions formatted with the data for Sale Amount
     *  Sale Amount field initially contains an object with a property for both amount and currency data.
     *  To make these values compatible with CSV format, this restructuring splits each field
     *  into two separate columns: one for the amount and another for the currency.
     * */
    const reformattedCommissions: AppGroupCsvCommissionWithMerchantName[] = csvCommissions.map(column => ({
      ...column,
      SaleAmount: column.SaleAmount?.Amount,
      SaleAmountCurrency: column.SaleAmount?.Currency,
    }));

    /**
     * Columns formatted for new Currency column corresponding to Sale Amount, Partner Commission, and User Commission
     * */
    const reformattedColumns: AppGroupCsvCommissionColumn[] = [];
    visibleColumns.forEach(column => {
      reformattedColumns.push(column as AppGroupCsvCommissionColumn);
      if (column.accessor === 'SaleAmount') {
        reformattedColumns.push({
          name: `${column.name} Currency`,
          accessor: `${column.accessor}Currency`,
        });
      }
    });
    const csv = exportJSONToCSV(reformattedCommissions, reformattedColumns);

    if (csv) {
      const blob = new Blob([csv], { type: 'text/csv' });

      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = URL.createObjectURL(blob);

      a.setAttribute(
        'download',
        `${adminName.split(' ').join('_')}_Commission_History_${formattedStartDate}_to_${formattedEndDate}.csv`,
      );

      // Trigger download by simulating click
      a.click();

      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

      // Close edit columns menu
      setEditColumnsAnchorEl(null);

      !hasWfPermissions &&
        ReactGA.event('click_export', {
          action: 'click',
          appId: adminId,
          userId: user!.sub,
          pageName: 'Commission History',
          totalRecords: loadedCommissions.length,
        });
    } else {
      setDownloadError(true);
      setTimeout((): void => setDownloadError(false), 3000);
    }
    setIsDownloading(false);
  };

  useEffect(() => {
    if (!startDate || !endDate || !deviceToken) return;
    generateCommissions();
  }, [startDate, endDate, searchTerm, deviceToken]);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchTerm(value);
      }, 500),
    [setSearchTerm],
  );

  const onSearchCommissionHistoryHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      debouncedSearch(event.target.value);
    },
    [debouncedSearch],
  );

  const updateColumns = (nextColumns?: CombinedColumn[]): void => {
    if (!nextColumns) return;

    dispatch(
      setAppGroupCommissionColumns({
        columns: nextColumns as AppGroupCommissionColumn[],
      }),
    );
  };

  const visibleColumns = useMemo((): CombinedColumn[] => {
    return columns.filter((column: CombinedColumn) => column.visible);
  }, [columns]);

  const hasDatesSelected = useMemo((): boolean => {
    return !!startDate && !!endDate;
  }, [startDate, endDate]);

  const addHeaderMargin = useMemo((): boolean => {
    return loadedCommissions.length > 20;
  }, [loadedCommissions]);

  if (!isDeviceTokenFetching && deviceTokenError) {
    console.error(deviceTokenError);
  }

  return (
    <div className="app-group-commission-history">
      <Card className="flex flex-wrap mb-7 justify-between items-center p-5">
        <DateRangePicker
          className="flex items-center font-montserrat shadow-subtle rounded h-10"
          label="Event Date"
          startDate={startDate ? new Date(startDate) : null}
          endDate={endDate ? new Date(endDate) : null}
          onDateChange={handleDateChange}
          onDateClear={() => {
            setInputValue('');
            setSearchTerm('');
          }}
        />
        <div className="flex items-center md:basis-full xl:basis-auto xs:flex-wrap xl:flex-nowrap">
          <TextField
            className="h-10 grow-2 xs:w-full xs:max-w-none xs:basis-full mb-1.5 mt-3 xl:mt-1.5 rounded border-gray-50 shadow-subtle"
            placeholder="Search"
            sx={{
              fieldset: { border: '0' },
            }}
            InputProps={{
              className: 'box-border py-2.5 px 3.5 h-10 font-montserrat',
              endAdornment: (
                <InputAdornment position="end">
                  <div className="search-icon">
                    {inputValue ? (
                      <HighlightOff
                        onClick={() => {
                          setInputValue('');
                          setSearchTerm('');
                        }}
                        className="search-bar-icon"
                      />
                    ) : (
                      <Search className="w-6 h-6" />
                    )}
                  </div>
                </InputAdornment>
              ),
            }}
            onChange={(event): void => {
              event.persist();
              setInputValue(event.target.value);
              onSearchCommissionHistoryHandler(event);
            }}
            value={inputValue}
          />
          <div className="btn-dropdown flex xs:basis-full">
            <Button
              className="button-table-action flex justify-between m-1.5 min-w-fit whitespace-nowrap max-w-xs text-base hover:border-0 xs:w-full xs:max-w-none xs:ml-0 xl:ml-3"
              disabled={false}
              variant="outlined"
              aria-controls="edit-columns-menu"
              aria-haspopup="true"
              endIcon={
                <Icon>
                  <img src={menuBookIcon} height={18} width={18} alt="Columns icon" />
                </Icon>
              }
              onClick={e => setEditColumnsAnchorEl(e.currentTarget)}
              fullWidth={true}
            >
              Edit Columns
            </Button>
            <Menu
              id="edit-columns-menu"
              anchorEl={editColumnsAnchorEl}
              keepMounted
              open={!!editColumnsAnchorEl}
              onClose={() => setEditColumnsAnchorEl(null)}
            >
              <ChooseColumnsMenu
                columns={columns}
                updateColumns={updateColumns}
                callbackVisible={callbackVisible}
                toggleCallback={() => setCallbackVisible(x => !x)}
                isInAppGroupOverview={true}
              />
            </Menu>
            <Button
              className="progress-wrapper button-table-action flex justify-between m-1.5 min-w-fit whitespace-nowrap max-w-xs text-base hover:border-0 xs:w-full xs:max-w-none"
              disabled={loadedCommissions.length === 0 || downloadError || isDownloading || !hasDatesSelected}
              onClick={exportToCsv}
              endIcon={
                downloadError ? (
                  <ErrorIcon />
                ) : (
                  <Icon>
                    <img src={exportIcon} height={18} width={18} alt="Export icon" />
                  </Icon>
                )
              }
            >
              {isDownloading && <CircularProgress className="progress" size={18} />}
              Export
            </Button>
            <Link
              className="flex items-center no-underline p-3"
              href="https://kb.wildfire-corp.com/article/ygwr-commission-history"
              target="_blank"
            >
              <Icon>
                <img className="align-top" src={infoIcon} height={24} width={24} alt="More information icon" />
              </Icon>
            </Link>
          </div>
        </div>
      </Card>
      <div className="app-group-commission-history-table-container">
        <CommissionHistoryTableV2
          columns={visibleColumns}
          commissions={loadedCommissions}
          applicationId={adminId}
          isLoading={isLoading}
          hasDatesSelected={hasDatesSelected}
          hasNextPage={hasNextPage}
          loadMoreItems={handleLoadMoreCommissions}
          hasCommissionError={Boolean(commissionError)}
          addHeaderMargin={addHeaderMargin}
          callbackVisible={callbackVisible}
          updateColumns={updateColumns}
          isInAppGroupOverview={true}
        />
      </div>
      {modalOpen && (
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          classes={{
            paper: 'modal-padding',
          }}
        >
          <div>
            <div className="close-icon" onClick={() => setModalOpen(false)}>
              <Clear />
            </div>
            <p>The data you requested is too large to download directly.</p>
            {commissionDownloadError ? (
              <p>
                There was an issue starting a download process for your data, please try to download your data again to
                create a new download process.
              </p>
            ) : (
              <p>
                Please visit the{' '}
                <RouterLink to={`/${adminId}/app-group-commission-download`}>Commission History Download</RouterLink>{' '}
                page, where it will be available to download soon.
              </p>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default AppGroupCommissionHistory;
