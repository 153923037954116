import React, { useCallback, useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { CircularProgress, IconButton, InputAdornment, Link, MenuItem, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import { parseVersion, findRtkErrorMessage } from 'helpers';
import { useGetApplicationCallbackQuery, useUpdateApplicationCallbackMutation } from 'reduxState/store/application/api';
import './ApplicationSetting.scss';

const versions = ['V2', 'V3', 'V4'];

const ApplicationSetting = ({ applicationId }: { applicationId: string }): JSX.Element => {
  const [callbackUrl, setCallbackUrl] = useState('');
  const [callbackKey, setCallbackKey] = useState('');
  const [callbackVersion, setCallbackVersion] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showKey, setShowKey] = useState(false);

  const { data: appCallbackData, error: appCallbackError } = useGetApplicationCallbackQuery({ appId: applicationId });
  const [
    updateApplicationCallback,
    { isLoading: isUpdatingAppCallback, error: appCallbackUpdateError },
  ] = useUpdateApplicationCallbackMutation();

  const getSettings = useCallback(() => {
    if (appCallbackData) {
      setCallbackKey(appCallbackData.CallbackKey);
      setCallbackUrl(appCallbackData.CallbackURL);
      setCallbackVersion(parseVersion(appCallbackData.CallbackVersion));
    } else if (appCallbackError) {
      toast.error('Failed to retrieve application callback.');
      console.error(appCallbackError);
    }
    setIsLoading(false);
  }, [appCallbackData]);

  const updateCallback = async (): Promise<void> => {
    const body = {
      CallbackURL: callbackUrl,
      CallbackKey: callbackKey,
      CallbackVersion: callbackVersion,
    };

    updateApplicationCallback({
      appId: applicationId,
      body,
    });
  };

  useEffect((): void => {
    getSettings();
  }, [appCallbackData]);

  return (
    <div className="application-setting">
      <h1 className="text-muted-dark-purple">Application Settings</h1>
      <>
        <TextField
          variant="outlined"
          customLabel={
            <div className="application-setting-custom-label-container">
              <span>Callback URL</span>
              <Link
                href="https://kb.wildfire-corp.com/article/kfgi-syncing-commission-data-via-api-and-callbacks"
                target="_blank"
              >
                <InfoIcon />
              </Link>
            </div>
          }
          disabled={isLoading}
          value={callbackUrl}
          onChange={e => setCallbackUrl(e.target.value)}
          error={!callbackUrl}
          inputProps={{ 'aria-label': 'Callback URL' }}
          helperText={!callbackUrl ? 'Callback URL required' : ''}
        />
        <TextField
          label="Callback Key"
          variant="outlined"
          disabled={isLoading}
          value={callbackKey}
          onChange={e => setCallbackKey(e.target.value)}
          type={showKey ? 'text' : 'password'}
          inputProps={{ 'aria-label': 'Callback Key' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowKey(!showKey)} size="large">
                  {showKey ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!callbackKey}
          helperText={!callbackKey ? 'Callback Key required' : ''}
        />
        <TextField
          variant="outlined"
          customLabel={
            <div className="application-setting-custom-label-container">
              <span>Callback Version</span>
              <Tooltip title="Ensure the callback version you select matches the commission API version" arrow>
                <InfoIcon style={{ color: '#3f51b5' }} />
              </Tooltip>
            </div>
          }
          disabled={isLoading}
          value={callbackVersion}
          onChange={e => setCallbackVersion(e.target.value)}
          error={!callbackVersion}
          helperText={!callbackVersion ? 'Callback Version required' : ''}
          inputProps={{ 'aria-label': 'Callback Version', 'data-testid': 'callback-version' }}
          select
          style={{ width: '20%' }}
          className="version-select"
        >
          {versions.map(version => (
            <MenuItem key={version} value={version}>
              {version}
            </MenuItem>
          ))}
        </TextField>
        <div className="disclaimer">
          The Callback URL and Callback Key are cached for 30 minutes. Please allow enough time for the cache to clear
          before testing changes.
        </div>
        {appCallbackUpdateError && (
          <div className="error">
            There was an error saving your application's settings: {findRtkErrorMessage(appCallbackUpdateError)}
          </div>
        )}
        <div className="application-setting-controls">
          <Button
            className="button-orange application-setting-save-button"
            onClick={updateCallback}
            disabled={isUpdatingAppCallback || !callbackUrl || !callbackKey || !callbackVersion}
            endIcon={isUpdatingAppCallback ? <CircularProgress size={20} /> : null}
          >
            Save
          </Button>
        </div>
      </>
    </div>
  );
};

export default ApplicationSetting;
