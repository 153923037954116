import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Check, Warning } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, IconButton, InputAdornment } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { inviteUserToApplication } from 'api/user';
import Loading from 'components/Loading/Loading';
import TextField from 'components/TextField/TextField';
import UsersTable from 'components/UsersTable/UsersTable';
import { hasWildfirePermissionsSelector, userRolesSelector } from 'helpers/auth0';
import { useGetDeviceQuery, useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { AppGroup } from 'reduxState/store/user/types';
import './UserManagement.scss';

type UserType = 'user' | 'owner';
type InviteStatus = 'success' | 'failed' | 'unauthorized';

const UserManagement: FC = () => {
  const [userInviteStatus, setUserInviteStatus] = useState<InviteStatus | null>(null);
  const [userTypeToCreate, setUserTypeToCreate] = useState<UserType | null>(null);
  const [inviteUserEmail, setInviteUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth0();

  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const isOwner = userRolesSelector(user).includes('owner');
  const { adminAppId } = useParams();
  const { data: deviceData } = useGetDeviceQuery(adminAppId as string, { skip: !adminAppId });
  const deviceToken = deviceData?.DeviceToken;

  const { data: applicationsData, isFetching } = useGetUserApplicationsQuery();
  const allAppGroups: AppGroup[] = applicationsData?.appGroups || [];
  const appGroup =
    allAppGroups.find((appGroup: AppGroup) => appGroup.adminId.toString() === adminAppId) || ({} as AppGroup);

  if (!adminAppId) {
    if (!isOwner) {
      navigate('/');
    }
    return <></>;
  }

  if (isFetching && isEmpty(appGroup)) {
    return <Loading />;
  }

  const toggleUserForm = (userType: UserType) => {
    if (userTypeToCreate !== userType) {
      setUserTypeToCreate(userType);
    } else {
      setUserTypeToCreate(null);
    }
  };

  const onCreateUser = async () => {
    if (!hasWfPermissions && userTypeToCreate === 'owner') {
      setUserInviteStatus('unauthorized');
      return;
    }

    const appRoleName = appGroup.applications[0].partnerId;

    if (!appRoleName || !deviceToken || !userTypeToCreate) return;

    setIsLoading(true);
    const response = await inviteUserToApplication(
      adminAppId,
      deviceToken,
      inviteUserEmail,
      appRoleName,
      userTypeToCreate,
    );
    setIsLoading(false);
    setUserInviteStatus(response?.ok ? 'success' : 'failed');
  };

  return (
    <>
      <h2>User Management</h2>
      <div className="user-management-wrapper">
        <Button
          className="button-orange"
          onClick={() => toggleUserForm('user')}
          endIcon={React.cloneElement(
            userTypeToCreate === 'user' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />,
          )}
        >
          Add User
        </Button>
        {hasWfPermissions && (
          <Button
            className="button-orange ml-3"
            onClick={() => toggleUserForm('owner')}
            endIcon={React.cloneElement(
              userTypeToCreate === 'owner' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />,
            )}
          >
            Add Admin User
          </Button>
        )}
        {Boolean(userTypeToCreate) && (
          <div className="user-management-add-user-wrapper">
            {userInviteStatus === 'success' && (
              <div className="user-invite-success">
                <Check htmlColor="#33cccc" />

                <span>User added successfully. Email invite sent.</span>
              </div>
            )}

            {userInviteStatus === 'failed' && (
              <div className="user-invite-error">
                <Warning htmlColor="red" />

                <span>Failed to invite user. Please try again later.</span>
              </div>
            )}

            {userInviteStatus === 'unauthorized' && (
              <div className="user-invite-error">
                <Warning htmlColor="red" />

                <span>You do not have permission to create users of this type.</span>
              </div>
            )}
            <h6>Add {userTypeToCreate === 'user' ? 'User' : 'Admin User'}</h6>

            <p className="add-user-description">
              Enter a work email and we'll send an invite to this address. The invited user will have the same access as
              you, EXCEPT they will not be able to add or remove users.
            </p>

            <TextField
              variant="outlined"
              disabled={isLoading}
              value={inviteUserEmail}
              onChange={e => setInviteUserEmail(e.target.value)}
              type="email"
              placeholder="Work email address"
              wrapperClassName="full-width"
              className="full-width"
              InputProps={{
                style: { width: '100%' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setInviteUserEmail('')}>
                      {inviteUserEmail ? <CloseIcon /> : ''}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button className="button-orange" onClick={onCreateUser}>
              Invite
            </Button>
          </div>
        )}
        <UsersTable roleName={appGroup.applications[0].partnerId} appId={appGroup.adminId.toString()} />
      </div>
    </>
  );
};

export default UserManagement;
